.about {
  margin-bottom: 185px;
}

.about__inner {
  max-width: 990px;
  position: relative;
}

.about__header {
  display: flex;
  flex-direction: column;
}

.about__title,
.about__text,
.about__timetext,
.about__timedate {
  font-family: MillerMedium;
  margin-top: 0;
}

.about__title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 10px;
}

.about__text {
  font-size: 16px;
  line-height: 24px;
  color: $lightgreen;
  text-transform: uppercase;
  margin-bottom: 97px;
}

.about__list {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.about__itemtitle {
  font-family: MillerMedium;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 24px;
  line-height: 32px;
  position: relative;
}

.about__itemtitle::before {
  content: "";
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  max-width: 450px;
  border-bottom: 4px solid $green;
}

.about__itemtext {
  margin-top: 0;
  font-size: 16px;
  line-height: 24px;
  color: $grey;
  max-width: 420px;
}

.about__item:not(:last-child) {
  margin-bottom: 130px;
}

.about__time {
  width: 450px;
  border: 1px solid $lightgreen;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 57%;
  left: 50%;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.about__timetext {
  margin-bottom: 7px;
  font-size: 24px;
  line-height: 32px;
}

.about__timedate {
  font-size: 64px;
  line-height: 60px;
  letter-spacing: -0.5px;
  margin-bottom: 0;
}

@media (max-width: $max-tablet-width) {
  .about {
    margin-bottom: 50px;
  }

  .about__inner {
    max-width: 750px;
  }

  .about__header {
    align-items: center;
  }

  .about__title {
    font-size: 30px;
  }

  .about__text {
    max-width: 300px;
    text-align: center;
  }

  .about__itemtitle::before {
    max-width: 350px;
    border-bottom: 2px solid $green;
  }

  .about__time {
    width: 350px;
  }

  .about__timedate {
    font-size: 54px;
  }
}

@media (max-width: $max-mobile-width) {
  .about__list {
    grid-template-columns: 1fr;
    grid-template-rows: 200px 200px 200px 200px;
    text-align: center;
  }

  .about__text {
    margin-bottom: 50px;
  }

  .about__time {
    width: 310px;
    top: 75%;
    left: 50%;
    transform: translateX(-50%)
  }

}

